import React, { useState, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import Header from "./components/Header";
import { accessTokenAtom, isSignedInAtom } from "./utils/Atoms";
import AddQuestion from "./screens/AddQuestionScreen/AddQuestion";
import { useAtom } from 'jotai';
import Login from './screens/LoginScreen/Login';
import { SELF_GET } from './utils/Requests';
import { useMutation } from '@tanstack/react-query';

function Router() {

  const [isSignedIn, setIsSignedIn] = useAtom(isSignedInAtom);


  const { mutate } = useMutation({
    mutationKey: ['self'],
    mutationFn: SELF_GET,
    onSuccess: (data) => {
      console.log('Data:', data);
      setIsSignedIn(true);
    },
    onError: (error) => {
      console.log('Error:', error);
      setIsSignedIn(false);
    },
  })

  useEffect(() => {
    mutate()
  }, []);
  return (
    <div className="container">
      <Header />
      <Toaster position="top-right" reverseOrder={false} />
      {isSignedIn ? <AddQuestion /> : <Login />}
      {/* <AddQuestion /> */}
    </div>
  );
}

export default Router;