import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Router from "./Router";
const queryClient = new QueryClient({})

function App() {



  return (
    <QueryClientProvider client={queryClient}>
      <Router />
    </QueryClientProvider>
  );
}

export default App;
